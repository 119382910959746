import {
    AmazonType,
    ApiType,
    AppConfiguration,
    AppType,
    AsignaturaType,
    CargaNominaConf,
    CarrerasConf,
    EvaluacionTipoMap,
    HojaRespuestaType,
    PlataformaType
} from "@puntaje/shared/core"

export class BaseConfig implements AppConfiguration {
    cargaNomina = {
        nomina: {
            alumnos: "cargar_nomina_alumnos_zero"
        }
    }

    navegacion = {
        urlHistorial: ["/evaluaciones/resultados/historial"]
    }

    generar = {
        generar: false,
        intrumentosRecomendados: true,
        pruebasPropias: false,
        buscador: true
    }

    registro = {
        enableCarreras: false,
        universidades: [],
        amount: 3,
        enableNivel: false,
        enableColegio: false,
        enableTelefono: false,
        enableFechaNacimiento: false,
        nombresObligatorio: false,
        apellidoPaternoObligatorio: false,
        apellidoMaternoObligatorio: false,
        nivelObligatorio: false,
        carrerasObligatorio: false,
        colegioObligatorio: false,
        fechaNacimientoObligatorio: false,
        telefonoObligatorio: false
    }
    carreras = {
        ponderables: [
            {
                orden: 1,
                ponderable: "Matemática",
                asignaturaAsociada: "Matemática",
                tipo: "calificable"
            },
            {
                orden: 2,
                ponderable: "Lenguaje y Comunicación",
                asignaturaAsociada: "Lenguaje",
                tipo: "calificable"
            },
            {
                orden: 3,
                ponderable: "Historia y Ciencias Sociales",
                asignaturaAsociada: "Historia y Ciencias Sociales",
                tipo: "calificable",
                categoria: "ciencias-historia"
            },
            {
                orden: 4,
                ponderable: "Ciencias",
                asignaturaAsociada: "Ciencias PSU",
                tipo: "calificable",
                categoria: "ciencias-historia"
            },
            {
                orden: 5,
                ponderable: "NEM",
                alias: "Puntaje NEM",
                clase: "nem",
                tipo: "ingresable",
                field: "nem",
                minValue: 150,
                maxValue: 850
            },
            {
                orden: 6,
                ponderable: "Ranking",
                clase: "ranking",
                tipo: "ingresable",
                field: "ranking",
                minValue: 150,
                maxValue: 850
            }
        ]
    } as CarrerasConf
    app = {
        assets: {
            logoLarge: "assets/img/logos/logoLarge.png",
            logoMedium: "assets/img/logos/logoMedium.png",
            logoSmall: "assets/img/logos/logoSmall.png",
            logoLargeLight: "assets/img/logos/logoLargeLight.png",
            logoMediumLight: "assets/img/logos/logoMediumLight.png",
            logoSmallLight: "assets/img/logos/logoSmallLight.png",
            logoSquare: "assets/img/logos/logoSquare.png",
            logoSquareLight: "assets/img/logos/logoSquareLight.png",
            streamingOffline: ""
        },
        name: "profesores",
        loader: "aprendolibre2",
        routeUser: null,
        routeUserInfo: null,
        paths: {
            pendingInfo: "/pending_info",
            landing: "/landing"
        },
        baseStyle: {
            navbar: {
                logo: "logoSmall",
                logoMobile: "logoSquare"
            },
            footer: {
                logo: "logoSmallLight"
            },
            landing: {
                logo: "logoLarge",
                subtitleHasShadow: true,
                logoFooter: "logoSmallLight",
                backgroundImage:
                    "https://s3-us-west-1.amazonaws.com/bucket-puntaje-reloaded/clones/0b8e7848726592e1a9941755a58ce90bfondo-landing-zero (1).png",
                subtitle: "Contigo en todas y a todas."
            },
            sharedLayout: {
                backgroundImage:
                    "https://s3-us-west-1.amazonaws.com/bucket-puntaje-reloaded/clones/485277d158fd14206ff84e01198edfd2fondo-landing-zero (1).png",
                logo: "logoLargeLight"
            }
        },
        navigationByApp: {
            alumnos: [
                {
                    icon: "prueba",
                    name: "Realizar prueba",
                    route: "/evaluaciones/recomendadas"
                },
                {
                    icon: "resultados",
                    name: "Resultados",
                    route: "/pruebas/resultados"
                },
                {
                    icon: "dialogos",
                    name: "Centro de ayuda",
                    route: "/ayudas"
                }
            ],
            profesores: [
                {
                    icon: "editar-prueba",
                    name: "Evaluar",
                    route: "/pruebas/generar_prueba"
                },
                {
                    icon: "resultados",
                    name: "Resultados",
                    route: "/evaluaciones/resultados"
                },
                {
                    icon: "dialogos",
                    name: "Centro de ayuda",
                    route: "/ayudas"
                }
            ]
        },
        showcasesByApp: {
            alumnos: [
                {
                    orden: 0,
                    nombre: "Datos Usuario",
                    columna: 0,
                    selected: true,
                    identificador: "usuario-showcasebox"
                },
                {
                    orden: 1,
                    nombre: "Noticias",
                    columna: 0,
                    selected: true,
                    identificador: "noticias-alumnos-showcasebox"
                },
                {
                    orden: 0,
                    nombre: "Menu box",
                    columna: 1,
                    selected: true,
                    identificador: "base-menu-showcasebox"
                },
                {
                    orden: 1,
                    nombre: "Notificaciones",
                    columna: 1,
                    selected: true,
                    identificador: "notificaciones-showcasebox"
                }
            ],
            profesores: [
                {
                    orden: 0,
                    nombre: "Menu box",
                    columna: 1,
                    selected: true,
                    identificador: "base-menu-showcasebox"
                },
                {
                    orden: 1,
                    nombre: "Lista establecimientos",
                    columna: 1,
                    selected: true,
                    identificador: "lista-establecimientos-profesor"
                },
                {
                    orden: 0,
                    nombre: "Datos Usuario",
                    columna: 2,
                    selected: true,
                    identificador: "usuario-showcasebox"
                },
                {
                    orden: 1,
                    nombre: "Cursos profesor",
                    columna: 2,
                    selected: true,
                    identificador: "grupo-usuarios-profesor-showcase"
                },
                {
                    orden: 2,
                    nombre: "Noticias profesor",
                    columna: 2,
                    selected: true,
                    identificador: "noticias-showcasebox"
                }
            ]
        },
        menuConfig: {
            tipo: "showcasebox"
        }
    } as AppType

    amazon = {
        s3BucketUri: "https://s3-us-west-1.amazonaws.com/bucket-puntaje-reloaded/",
        s3BucketUriNebu: "https://s3-us-west-1.amazonaws.com/bucket-nebuloso/"
    } as AmazonType

    api = {
        checkTokenPath: "/check_token",
        authPath: "/authenticate"
    } as ApiType

    asignaturas = [
        {
            asignatura: "Nivelación",
            icono: "prueba",
            clase: "len",
            abreviacion: "Nivelación",
            asignaturaPlataforma: "Nivelación",
            evaluacionTipo: ["curricular", "diagnostico curricular"],
            materialVisible: true
        },
        {
            asignatura: "Habilitación",
            icono: "upload",
            clase: "mat",
            abreviacion: "Habilitación",
            asignaturaPlataforma: "Habilitación",
            evaluacionTipo: ["curricular", "diagnostico curricular"],
            materialVisible: true
        }
    ] as AsignaturaType[]

    plataforma = {
        name: "zero",
        id: null,
        pais: "chile",
        locale: "es-cl",
        lugarTipo: "Comuna",
        lugarLabel: "Comuna",
        identificadorUsuario: ["RUT"],
        identificadorEstablecimiento: ["rbd"],
        grupoUsuarioAlias: "curso",
        clasificacionTipoNivel: "curso",
        hideReforzamientos: true,
        enableTipoEscala: true,
        disableZopim: true,
        info: {
            social: {
                facebook: "",
                twitter: "",
                youtube: "",
                instagram: ""
            },
            soporte: {
                mail: ""
            },
            contacto: {
                telefono: "",
                mail: ""
            },
            companyName: "Universidad Autónoma",
            companySite: "https://www.uautonoma.cl/"
        },
        evaluacionTipoAdaptiveDefault: "diagnostico curricular",
        evaluacionDefault: "curricular",
        evaluacionResponder: "estandar",
        resumenGeneral: {
            curriculum: "curricular",
            generadores: [83, 15, 16, 17, 18, 84, 19, 20, 85]
        },
        tiposInstrumentoInformeUso: [],
        evaluacionTipos: ["curricular", "diagnostico curricular"],
        añoInicial: 2020,
        periodos: [
            {
                label: "",
                fechaInicial: [0, 1],
                fechaFinal: [11, 31, 23, 59, 59]
            }
        ],
        streamingDefault: null,
        iconSet: {
            noticiasShowcase: "megafono-o",
            establecimientosShowcase: "establecimiento-o",
            cursosShowcase: "comunidad-o"
        },
        instrumentoMensualTipo: "curricular",
        tiposNotificacion: [
            {
                tipo: "curricular mexico",
                label: "Pruebas"
            },
            {
                tipo: "duda",
                label: "Dudas"
            },
            {
                tipo: "respuesta_correcta_duda",
                label: "Respuestas Correctas Dudas"
            },
            {
                tipo: "respuesta_duda",
                label: "Respuestas Dudas"
            },
            {
                tipo: "retroalimentacion",
                label: "Retroalimentaciones"
            }
        ],
        showAyuda: true,
        enableAchievements: false,
        identificadorUsuarioAlias: null,
        hideReforzamiento: true,
        canTextHighlight: true,
        enableNotaAprobacionEscala: true
    } as PlataformaType

    evaluaciones = {
        curricular: {
            alias: "prueba",
            nombreTipoCalificacion: "%",
            layaoutResultadoCalificacion: "horizontal",
            cantidadPreguntas: 4,
            clasificaciones: {
                estadisticas: ["RA - U Autónoma"],
                groupBy: ["RA - U Autónoma"],
                clasificacionTiposTablaEspecificaciones: ["RA - U Autónoma"],
                configuration: [
                    {
                        name: "Resultados de aprendizaje",
                        htmlId: "RA_- U Autónoma",
                        hasHijos: true,
                        clasificacionTipo: "RA - U Autónoma"
                    }
                ],
                estadisticasEvolutivas: ["RA - U Autónoma"],
                desempenoEvaluacion: "RA - U Autónoma",
                methods: "clasificaciones",
                referencesTo: {
                    "objetivo de aprendizaje": "nivel 2 prueba de transición",
                    "nivel 1 prueba de transición": "nivel 1 prueba de transición",
                    "nivel 2 prueba de transición": "nivel 3 prueba de transición"
                },
                cambiarPregunta: ["nivel 1 prueba de transición"]
            },
            clasificacionDistribucion: "Resultado de aprendizaje",
            clasificacionTipoResultados: "eje temático LGE",
            clasificacionMaterial: {
                clasificacion: "nivel 1 prueba de transición",
                label: "eje"
            },
            planPersonalClasificacionMateriales: [],
            clasificacionTiposEstadisticas: [
                {
                    nombre: "RA - U Autónoma",
                    alumnos: true,
                    compuesto: true,
                    profesores: true,
                    informeAnual: true
                },
                {
                    nombre: "habilidad prueba de transición",
                    compuesto: true,
                    profesores: true,
                    informeAnual: true
                }
            ],
            cursoTipo: "curso PSU",
            cursos: [
                {
                    label: "7º Medio",
                    clasificacion: "Séptimo Básico"
                },
                {
                    label: "8º Medio",
                    clasificacion: "Octavo Básico"
                },
                {
                    label: "1º Medio",
                    clasificacion: "Primero Medio"
                },
                {
                    label: "2º Medio",
                    clasificacion: "Segundo Medio"
                },
                {
                    label: "3º Medio",
                    clasificacion: "Tercero Medio"
                },
                {
                    label: "4º Medio",
                    clasificacion: "Cuarto Medio"
                }
            ],
            cursosMaterial: [
                {
                    id: 7,
                    label: "7º Básico",
                    clasificacion: "Séptimo Básico"
                },
                {
                    id: 8,
                    label: "8º Básico",
                    clasificacion: "Octavo Básico"
                },
                {
                    id: 9,
                    label: "1º Medio",
                    clasificacion: "Primero Medio"
                },
                {
                    id: 10,
                    label: "2º Medio",
                    clasificacion: "Segundo Medio"
                },
                {
                    id: 11,
                    label: "3º Medio",
                    clasificacion: "Tercero Medio"
                },
                {
                    id: 12,
                    label: "4º Medio",
                    clasificacion: "Cuarto Medio"
                }
            ],
            estrellas: [
                {
                    max: 2,
                    min: 1
                },
                {
                    max: 3,
                    min: 2
                },
                {
                    max: 4,
                    min: 3
                },
                {
                    max: 5,
                    min: 4
                },
                {
                    max: 6,
                    min: 5
                },
                {
                    max: 7,
                    min: 6
                }
            ],
            nivelTipoInstrumentoInformeFinal: ["1º Medio"],
            rangoCalificacion: {
                min: 0,
                tickInterval: 20,
                max: 100
            },
            streamingChannelAlias: "Clases",
            hideEstadistica: false,
            baseUrl: "/pruebas"
        },
        "diagnostico curricular": {
            alias: "prueba",
            cantidadPreguntas: 5,
            clasificaciones: {
                estadisticas: ["nivel 1 prueba de transición", "nivel 2 prueba de transición"],
                estadisticasEvolutivas: "nivel 1 prueba de transición,nivel 2 prueba de transición",
                desempenoEvaluacion: "nivel 1 prueba de transición,nivel 2 prueba de transición",
                groupBy: ["nivel 1 prueba de transición,nivel 2 prueba de transición"],
                clasificacionTiposTablaEspecificaciones: [
                    "nivel 1 prueba de transición",
                    "habilidad prueba de transición"
                ],
                methods: "clasificaciones",
                referencesTo: {
                    "nivel 2 prueba de transición": "nivel 1 prueba de transición"
                },
                configuration: [
                    {
                        name: "Nivel 1",
                        htmlId: "nivel1",
                        hasHijos: true,
                        clasificacionTipo: "nivel 1 prueba de transición"
                    }
                ],
                cambiarPregunta: ["nivel 1 prueba de transición"]
            },
            clasificacionDistribucion: "ejes",
            clasificacionMaterial: {
                clasificacion: "nivel 1 prueba de transición",
                label: "eje"
            },
            clasificacionTiposEstadisticas: [
                {
                    nombre: "nivel 1 prueba de transición",
                    alumnos: true,
                    compuesto: true,
                    profesores: true,
                    informeAnual: true
                },
                {
                    nombre: "nivel 2 prueba de transición",
                    alumnos: true,
                    compuesto: true,
                    profesores: true,
                    informeAnual: false
                },
                {
                    nombre: "habilidad prueba de transición",
                    alumnos: false,
                    compuesto: true,
                    profesores: true,
                    informeAnual: true
                }
            ],
            cursoTipo: "curso PSU",
            cursos: [
                {
                    label: "7º Medio",
                    clasificacion: "Séptimo Básico"
                },
                {
                    label: "8º Medio",
                    clasificacion: "Octavo Básico"
                },
                {
                    label: "1º Medio",
                    clasificacion: "Primero Medio"
                },
                {
                    label: "2º Medio",
                    clasificacion: "Segundo Medio"
                },
                {
                    label: "3º Medio",
                    clasificacion: "Tercero Medio"
                },
                {
                    label: "4º Medio",
                    clasificacion: "Cuarto Medio"
                }
            ],
            cursosMaterial: [],
            estrellas: [
                {
                    max: 266,
                    min: 150
                },
                {
                    max: 383,
                    min: 267
                },
                {
                    max: 500,
                    min: 384
                },
                {
                    max: 617,
                    min: 501
                },
                {
                    max: 734,
                    min: 618
                },
                {
                    max: 850,
                    min: 735
                }
            ],
            nivelTipoInstrumentoInformeFinal: ["1º Medio", "2º Medio", "3º Medio", "4º Medio"],
            rangoCalificacion: {
                min: 0,
                tickInterval: 100,
                max: 100
            },
            streamingChannelAlias: "Clases Prueba de transición",
            planPersonalClasificacionMateriales: [],
            baseUrl: "/evaluaciones"
        }
    } as EvaluacionTipoMap

    instrumentosPredefinidos = {
        zonas: ["Columna 1", "Columna 2", "Columna 3"]
    }

    hojaRespuesta = {
        sectores: [
            [1, 2],
            [2, 3],
            [3, 4],
            [5, 2],
            [2, 7],
            [7, 4],
            [5, 6],
            [6, 7],
            [7, 8]
        ],
        alternativas: 5
    } as HojaRespuestaType

    mensajesLogin = {
        idPlaceholder: "Email o NUIP",
        invalidLoginId: "Debes incluir un correo electrónico o NUIP válido.",
        invalidLoginMessage1:
            "<strong>La combinación de correo electrónico/contraseña o NUIP/contraseña es incorrecta.</strong> Las contraseñas de ",
        invalidLoginMessage2:
            ' distinguen entre mayúsculas y minúsculas. Por favor, comprueba la tecla "Bloq Mayús". También puedes probar a borrar el caché y las cookies de tu navegador.'
    }
}
