import { Routes, RouterModule } from "@angular/router"
import { AuthGuard } from "@puntaje/shared/core"
import { TokenGuard } from "@puntaje/puntaje/services"
import { HomeEstablecimientosComponent } from "./home_establecimientos.component"
import { LoggedLayoutComponent } from "../layouts/logged_layout/logged_layout.component"
import { EstablecimientosAdministrarComponent } from "@puntaje/puntaje/core"

export const establecimientosRoutes: Routes = [
    {
        path: "establecimientos/administrar",
        component: LoggedLayoutComponent,
        children: [
            {
                path: "",
                component: EstablecimientosAdministrarComponent,
                canActivate: [TokenGuard, AuthGuard]
            }
        ]
    },
    { 
        path: "establecimientos", 
        component: HomeEstablecimientosComponent, canActivate: [TokenGuard, AuthGuard] 
    }
]

export const establecimientosRouting = RouterModule.forChild(establecimientosRoutes)
