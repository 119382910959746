import { NgModule } from "@angular/core"
import { FormsModule } from "@angular/forms"
import { CommonModule } from "@angular/common"
import { NoticiasModule } from "@puntaje/puntaje/new-modules/noticias"

import { routing } from "./profesores_noticias.routing"
import { UtilModule } from "@puntaje/shared/core"
import { ProfesoresLayoutsModule } from "../layouts/index"

import { PuntajeNoticiasModule } from "@puntaje/puntaje/core"

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ProfesoresLayoutsModule,
        UtilModule,
        NoticiasModule,
        routing,
        PuntajeNoticiasModule
    ],
    declarations: [],
    exports: []
})
export class ProfesoresNoticiasModule {}
