import { Routes, RouterModule } from "@angular/router"
import { AuthGuard } from "@puntaje/shared/core"
import { TokenGuard } from "@puntaje/puntaje/services"
import { EditDudaGuard, BanGuard } from "@puntaje/puntaje/services"
import {
    DudaComponent,
    DudaEditComponent,
    DudaNewComponent,
    DudasCategoriasComponent,
    DudasComponent
} from "@puntaje/puntaje/core"
import { LoggedLayoutComponent } from "../layouts/logged_layout/logged_layout.component"

export const routes: Routes = [
    {
        path: "dudas_categorias",
        component: LoggedLayoutComponent,
        children: [
            {
                path: "",
                component: DudasCategoriasComponent,
                canActivate: [TokenGuard, AuthGuard, BanGuard]
            }
        ]
    },
    {
        path: "dudas/new",
        component: LoggedLayoutComponent,
        children: [
            {
                path: "",
                component: DudaNewComponent,
                canActivate: [TokenGuard, AuthGuard, BanGuard]
            }
        ]
    },
    {
        path: "dudas",
        component: LoggedLayoutComponent,
        children: [
            {
                path: "",
                component: DudasComponent,
                canActivate: [TokenGuard, AuthGuard, BanGuard]
            }
        ]
    },
    {
        path: "dudas/:id",
        component: LoggedLayoutComponent,
        children: [
            {
                path: "",
                component: DudaComponent,
                canActivate: [TokenGuard, AuthGuard, BanGuard]
            }
        ]
    },
    {
        path: "dudas/:id/edit",
        component: LoggedLayoutComponent,
        children: [
            {
                path: "",
                component: DudaEditComponent,
                data: {
                    identifier: "id",
                    baseRoute: "dudas"
                },
                canActivate: [TokenGuard, AuthGuard, EditDudaGuard, BanGuard]
            }
        ]
    }
]

export const routing = RouterModule.forChild(routes)
