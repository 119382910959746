<div class="seccion">
    <loading-layout #loadingLayout [opaque]="true">
        <div>
            <cui-section-headline>
                1. Seleccione un método para generar
                {{ tipoEvaluacion | transformInstrumento: "conArticuloIndefinido" }}
            </cui-section-headline>
            <p class="tutorial">
                {{ tipoEvaluacion | transformInstrumento: "conArticuloPlural" | capitalize }} se generan a partir de un
                Instrumento de evaluación.
                <br />
                Seleccione un instrumento para generar la evaluación a partir de las siguientes opciones:
            </p>
            <!--     Instrumentos Existentes     -->

            <div class="select-container">
                <label>Seleccione un tipo:</label>
                <select
                    [(ngModel)]="generadorInstrumentoSelected"
                    (ngModelChange)="getGenerador($event)"
                    class="form-control"
                >
                    <option [ngValue]="undefined">Seleccione generador</option>
                    <option *ngFor="let generadorInstrumento of generadorInstrumentos" [ngValue]="generadorInstrumento">
                        {{ generadorInstrumento.generador_instrumento }}
                    </option>
                </select>
            </div>
            <br />
            <div *ngIf="generadorInstrumentoSelected" class="" id="tab_default_instrumento">
                <!--<h4 class="h4_sub_title_1"><fa name="arrow-right" class="highlight-chevron"></fa>&nbsp;Seleccione alguno de nuestros instrumentos recomendados</h4>-->
                <ng-container *ngFor="let tag of zonas">
                    <recomendado-ensayo-profesor
                        [asignatura]="asignatura"
                        [generadorInstrumentoSelected]="generadorInstrumentoSelected"
                        (onReadyInstrumento)="callbackRecomendado($event)"
                        (onLoadingInstrumento)="onExternalLoadingInstrumento()"
                    ></recomendado-ensayo-profesor>
                </ng-container>
                <p *ngIf="!showZonas" class="no-results">No hay contenido recomendado</p>
            </div>
        </div>
    </loading-layout>
</div>
<div *ngIf="instrumento">
    <cui-section-headline id="instrumentoSeleccionado">
        2. Compartir {{ tipoEvaluacion | transformInstrumento: "singular" }} a partir del Instrumento seleccionado de la
        sección "evaluar"
    </cui-section-headline>
    <p class="tutorial">
        Para terminar de generar {{ tipoEvaluacion | transformInstrumento: "conArticuloIndefinido" }}, elija la opción
        de Compartir en el menú.
    </p>
    <div class="row">
        <div class="col-md-4 inverted-col">
            <opciones-instrumento
                [instrumento]="instrumento"
                [tipoId]="1"
                [tipoEvaluacion]="tipoEvaluacion"
            ></opciones-instrumento>
        </div>
        <div class="col-md-8">
            <h3 class="titulo-instrumento">
                <div class="titulo-instrumento-wrap">
                    <span class="titulo-instrumento-icon"><ogr-icon [name]="'prueba'"></ogr-icon></span>
                    <span class="titulo-instrumento-text">
                        Instrumento #{{ instrumento.id }} - {{ asignatura.asignatura }}
                    </span>
                </div>
            </h3>
            <instrumento
                [enableReporte]="enableReporte"
                [instrumento]="instrumento"
                (ready)="onReadyInstrumento()"
            ></instrumento>
        </div>
    </div>
</div>
