import { Routes, RouterModule } from "@angular/router"
import { AuthGuard } from "@puntaje/shared/core"
import { TokenGuard } from "@puntaje/puntaje/services"
import { LoggedLayoutComponent } from "../layouts/logged_layout/logged_layout.component"
import { GrupoUsuariosComponent } from "@puntaje/puntaje/core"

export const grupoUsuariosRoutes: Routes = [
    {
        path: "grupo_usuarios",
        component: LoggedLayoutComponent,
        children: [
            {
                path: "",
                component: GrupoUsuariosComponent,
                canActivate: [TokenGuard, AuthGuard]
            }
        ]
    }
]

export const grupoUsuariosRouting = RouterModule.forChild(grupoUsuariosRoutes)
