import { Routes, RouterModule } from "@angular/router"
import { AuthGuard } from "@puntaje/shared/core"
import { TokenGuard } from "@puntaje/puntaje/services"

import { HomeSaberComponent } from "./home_saber.component"
import { HomeSaber11Component } from "./home_saber11.component"
import { HomePruebasComponent } from "./home_pruebas.component"

import { ResultadosEvaluacionComponent } from "./resultados-evaluacion/resultados-evaluacion.component"
import { LoggedLayoutComponent } from "../layouts/logged_layout/logged_layout.component"
import { EvaluationLayoutComponent } from "../layouts/evaluation_layout/evaluation_layout.component"
import {
    EstadisticasComponent,
    EvaluacionComponent,
    GenerarEnsayoProfesor2Component,
    GenerarEnsayoProfesorComponent,
    ProgresoComponent,
    RealizarEnsayoComponent,
    ResultadosEnsayoComponent,
    EvaluacionesCorregirComponent,
    ResultadosEnsayoAlumnoComponent,
    RevisarEnsayosComponent,
    GenerarEnsayoMultipleComponent,
    EnsayoInstanciasComponent,
    RevisarSimulacrosComponent,
    EstadisticasMultiplesComponent,
    LibroNotasComponent
} from "@puntaje/puntaje/core"

export const routes: Routes = [
    {
        path: "evaluaciones/resultados",
        component: LoggedLayoutComponent,
        children: [
            {
                path: "",
                component: ResultadosEvaluacionComponent,
                canActivate: [TokenGuard, AuthGuard],
                pathMatch: "full"
            }
        ]
    },
    {
        path: "ensayo_instancias",
        component: LoggedLayoutComponent,
        children: [
            {
                path: "",
                component: EnsayoInstanciasComponent,
                canActivate: [TokenGuard, AuthGuard]
            }
        ]
    },
    {
        path: "evaluaciones/resultados/libro",
        component: LoggedLayoutComponent,
        children: [
            {
                path: "",
                component: LibroNotasComponent,
                canActivate: [TokenGuard, AuthGuard]
            }
        ]
    },
    {
        path: "ensayos/generar_ensayo2/:asignatura_id",
        component: LoggedLayoutComponent,
        children: [
            {
                path: "",
                component: GenerarEnsayoProfesor2Component,
                canActivate: [TokenGuard, AuthGuard]
            }
        ]
    },
    {
        path: "ensayos/:id/realizar",
        component: EvaluationLayoutComponent,
        children: [
            {
                path: "",
                component: RealizarEnsayoComponent,
                canActivate: [AuthGuard]
            }
        ]
    },
    {
        path: "ensayo_instancias/:id",
        component: EvaluationLayoutComponent,
        children: [
            {
                path: "",
                component: ResultadosEnsayoComponent,
                canActivate: [AuthGuard]
            }
        ]
    },
    {
        path: "evaluaciones/resultados/historial",
        component: LoggedLayoutComponent,
        children: [
            {
                path: "",
                component: RevisarEnsayosComponent,
                canActivate: [AuthGuard]
            }
        ]
    },
    {
        path: "evaluaciones/generar_ensayo/:asignatura_id",
        component: LoggedLayoutComponent,
        children: [
            {
                path: "",
                component: GenerarEnsayoProfesorComponent,
                canActivate: [AuthGuard],
                data: {
                    enableBuscador: true,
                    enableReporte: false,
                    showTutorial: true,
                    enableSiteWarning: false
                }
            }
        ]
    },
    // {
    //     path: "evaluaciones/generar_ensayo2/:asignatura_id",
    //     component: GenerarEnsayo2Component,
    //     canActivate: [TokenGuard, AuthGuard]
    // },
    {
        path: "evaluaciones/generar_ensayo2/:asignatura_id",
        component: LoggedLayoutComponent,
        children: [
            {
                path: "",
                component: GenerarEnsayoProfesor2Component,
                canActivate: [TokenGuard, AuthGuard]
            }
        ]
    },
    {
        path: "evaluaciones/:id/realizar",
        component: EvaluationLayoutComponent,
        children: [
            {
                path: "",
                component: RealizarEnsayoComponent,
                canActivate: [AuthGuard]
            }
        ]
    },
    {
        path: "evaluacion_instancias/subir",
        component: LoggedLayoutComponent,
        children: [
            {
                path: "",
                component: EvaluacionesCorregirComponent,
                canActivate: [AuthGuard],
                data: {
                    titulo: "Subir resultados de Simulacros"
                }
            }
        ]
    },
    {
        path: "evaluacion_instancias/:id",
        component: EvaluationLayoutComponent,
        children: [
            {
                path: "",
                component: ResultadosEnsayoComponent,
                canActivate: [AuthGuard]
            }
        ]
    },
    {
        path: "evaluaciones/:id",
        component: LoggedLayoutComponent,
        children: [
            {
                path: "",
                component: EvaluacionComponent,
                canActivate: [AuthGuard]
            }
        ]
    },
    {
        path: "ensayo_instancias_estudiante/:id",
        component: EvaluationLayoutComponent,
        children: [
            {
                path: "",
                component: ResultadosEnsayoAlumnoComponent,
                canActivate: [TokenGuard, AuthGuard]
            }
        ]
    },

    {
        path: "saber/generar_simulacro/:asignatura_id",
        component: LoggedLayoutComponent,
        children: [
            {
                path: "",
                component: GenerarEnsayoProfesorComponent,
                canActivate: [TokenGuard, AuthGuard],
                data: {
                    enableSiteWarning: false
                }
            }
        ]
    },
    {
        path: "saber/compartidas",
        component: LoggedLayoutComponent,
        children: [
            {
                path: "",
                component: RevisarEnsayosComponent,
                canActivate: [TokenGuard, AuthGuard]
            }
        ]
    },
    {
        path: "saber/estadisticas",
        component: LoggedLayoutComponent,
        children: [
            {
                path: "",
                component: EstadisticasComponent,
                canActivate: [TokenGuard, AuthGuard]
            }
        ]
    },
    {
        path: "saber/progreso",
        component: LoggedLayoutComponent,
        children: [
            {
                path: "",
                component: ProgresoComponent,
                canActivate: [TokenGuard, AuthGuard]
            }
        ]
    },
    {
        path: "saber/resultados",
        component: LoggedLayoutComponent,
        children: [
            {
                path: "",
                component: EnsayoInstanciasComponent,
                canActivate: [TokenGuard, AuthGuard]
            }
        ]
    },
    {
        path: "saber",
        component: LoggedLayoutComponent,
        children: [
            {
                path: "",
                component: HomeSaberComponent,
                canActivate: [TokenGuard, AuthGuard]
            }
        ]
    },
    {
        path: "saber11/generar_simulacro/:asignatura_id",
        component: LoggedLayoutComponent,
        children: [
            {
                path: "",
                component: GenerarEnsayoProfesorComponent,
                canActivate: [TokenGuard, AuthGuard],
                data: {
                    enableSiteWarning: false
                }
            }
        ]
    },
    {
        path: "saber11/compartidas",
        component: LoggedLayoutComponent,
        children: [
            {
                path: "",
                component: RevisarEnsayosComponent,
                canActivate: [TokenGuard, AuthGuard]
            }
        ]
    },
    {
        path: "saber11/estadisticas",
        component: LoggedLayoutComponent,
        children: [
            {
                path: "",
                component: EstadisticasComponent,
                canActivate: [TokenGuard, AuthGuard]
            }
        ]
    },
    {
        path: "saber11/estadisticas_multiples",
        component: LoggedLayoutComponent,
        children: [
            {
                path: "",
                component: EstadisticasMultiplesComponent,
                canActivate: [TokenGuard, AuthGuard]
            }
        ]
    },
    {
        path: "saber11/progreso",
        component: LoggedLayoutComponent,
        children: [
            {
                path: "",
                component: ProgresoComponent,
                canActivate: [AuthGuard]
            }
        ]
    },
    {
        path: "saber11/resultados",
        component: LoggedLayoutComponent,
        children: [
            {
                path: "",
                component: EnsayoInstanciasComponent,
                canActivate: [AuthGuard]
            }
        ]
    },
    {
        path: "saber11",
        component: LoggedLayoutComponent,
        children: [
            {
                path: "",
                component: HomeSaber11Component,
                canActivate: [TokenGuard, AuthGuard]
            }
        ]
    },
    {
        path: "saber11/generar_simulacro",
        component: LoggedLayoutComponent,
        children: [
            {
                path: "",
                component: GenerarEnsayoMultipleComponent,
                canActivate: [TokenGuard, AuthGuard]
            }
        ]
    },
    {
        path: "saber11/simulacros_compartidos",
        component: LoggedLayoutComponent,
        children: [
            {
                path: "",
                component: RevisarSimulacrosComponent,
                canActivate: [TokenGuard, AuthGuard]
            }
        ]
    },
    {
        path: "pruebas/generar_prueba/:asignatura_id",
        component: LoggedLayoutComponent,
        children: [
            {
                path: "",
                component: GenerarEnsayoProfesorComponent,
                canActivate: [AuthGuard],
                data: {
                    enableBuscador: true,
                    enableReporte: false,
                    showTutorial: true,
                    enableSiteWarning: false
                }
            }
        ]
    },
    {
        path: "pruebas/generar_prueba",
        component: LoggedLayoutComponent,
        children: [
            {
                path: "",
                component: GenerarEnsayoProfesorComponent,
                canActivate: [TokenGuard, AuthGuard],
                data: {
                    enableSiteWarning: false
                }
            }
        ]
    },
    {
        path: "pruebas/compartidas",
        component: LoggedLayoutComponent,
        children: [
            {
                path: "",
                component: RevisarEnsayosComponent,
                canActivate: [TokenGuard, AuthGuard]
            }
        ]
    },
    {
        path: "pruebas/estadisticas",
        component: LoggedLayoutComponent,
        children: [
            {
                path: "",
                component: EstadisticasComponent,
                canActivate: [TokenGuard, AuthGuard]
            }
        ]
    },
    {
        path: "pruebas/progreso",
        component: LoggedLayoutComponent,
        children: [
            {
                path: "",
                component: ProgresoComponent,
                canActivate: [AuthGuard]
            }
        ]
    },
    {
        path: "pruebas/resultados",
        component: LoggedLayoutComponent,
        children: [
            {
                path: "",
                component: EnsayoInstanciasComponent,
                canActivate: [TokenGuard, AuthGuard]
            }
        ]
    },
    {
        path: "pruebas",
        component: LoggedLayoutComponent,
        children: [
            {
                path: "",
                component: HomePruebasComponent,
                canActivate: [TokenGuard, AuthGuard]
            }
        ]
    }
]

export const routing = RouterModule.forChild(routes)
