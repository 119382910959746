import { Component, EventEmitter, ViewChild } from "@angular/core"
import { ActivatedRoute, Router } from "@angular/router"
import { Subscription } from "rxjs"

import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig

import { Asignatura } from "@puntaje/nebulosa/api-services"
import { TitleService } from "@puntaje/shared/core"

@Component({
    templateUrl: "generar_ensayo_profesor2.component.html"
})
export class GenerarEnsayoProfesor2Component {
    asignaturaId: number
    tipoInstrumento: string
    asignatura: Asignatura
    sub: Subscription
    subQuery: Subscription

    constructor(protected titleService: TitleService, protected route: ActivatedRoute, protected router: Router) {}

    ngOnInit() {
        this.titleService.setTitle("titles.core.ensayos.generar_ensayo_profesor")
        this.sub = this.route.params.subscribe(params => {
            this.asignaturaId = +params["asignatura_id"]
        })

        this.subQuery = this.route.queryParams.subscribe(query => {
            this.tipoInstrumento = query["tipo_instrumento"] || "simce"
        })
    }

    onReady(asignatura) {
        this.asignatura = asignatura
    }

    ngOnDestroy() {
        this.sub.unsubscribe()
        this.subQuery.unsubscribe()
    }

    onReadyAsignaturaDropdown(asignaturaId) {
        this.router.navigate(["/ensayos/generar_ensayo2", asignaturaId], {
            queryParams: { tipo_instrumento: this.tipoInstrumento }
        })
    }
}
