import { NgModule } from "@angular/core"
import { CommonModule } from "@angular/common"
import { FormsModule } from "@angular/forms"

import { NotificacionesModule } from "@puntaje/puntaje/new-modules/notificaciones"
import { ProfesoresLayoutsModule } from "../layouts/profesores_layouts.module"

import { routing } from "./profesores_notificaciones.routing"
import { PuntajeNotificacionesModule } from "@puntaje/puntaje/core"

@NgModule({
    imports: [
        CommonModule, 
        FormsModule, 
        NotificacionesModule, 
        ProfesoresLayoutsModule, 
        routing,
        PuntajeNotificacionesModule
    ],
    exports: [],
    declarations: [],
    providers: []
})
export class ProfesoresNotificacionesModule {}
