import { Component, OnInit, ViewChild } from "@angular/core"
import { Router, ActivatedRoute } from "@angular/router"
import { MenuItemBoxData } from "@puntaje/shared/layouts"
import { MenuComponent } from "@puntaje/puntaje/new-modules/shared"
import { Asignaturas } from "@puntaje/nebulosa/api-services"
import { AuthService } from "@puntaje/shared/core"
import { Store } from "@ngrx/store"
import { State } from "@puntaje/puntaje/store"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig

@Component({
    templateUrl: "home.component.html",
    styleUrls: ["home.component.scss"]
})
export class HomeComponent extends MenuComponent implements OnInit {
    menuItems: MenuItemBoxData[] = []
    showcases = config.app.showcasesByApp?.[config.app.name] || [
        {
            nombre: "Datos Usuario",
            identificador: "usuario-showcasebox",
            columna: 0,
            orden: 0
        },
        {
            nombre: "Noticias profesor",
            identificador: "noticias-showcasebox",
            columna: 0,
            orden: 1
        },
        {
            nombre: "Cursos profesor",
            identificador: "grupo-usuarios-profesor-showcase",
            columna: 1,
            orden: 0
        },
        {
            nombre: "Lista establecimientos",
            identificador: "lista-establecimientos-profesor",
            columna: 1,
            orden: 1
        }
    ]

    showcasesColumna = this.showcases
        .sort((s1, s2) => s1.orden - s2.orden)
        .reduce((acc, showcase) => {
            acc[showcase.columna] = acc[showcase.columna] || []
            acc[showcase.columna].push(showcase)

            return acc
        }, {})

    constructor(protected router: Router, protected route: ActivatedRoute, protected store: Store<State>) {
        super(store)
    }

    ngOnInit() {
        super.ngOnInit()
        this.setMenuItems()
    }

    setMenuItems() {}
}
