<ll-titulo>Generar {{ tipoInstrumento | transformInstrumento: "singular" | titlecase }}</ll-titulo>
<ll-topside>
    <menu-asignaturas-dropdown
        [tipoEvaluacion]="tipoInstrumento"
        [asignaturaId]="asignaturaId"
        (onReady)="onReadyAsignaturaDropdown($event)"
    ></menu-asignaturas-dropdown>
</ll-topside>
<generar-ensayo-profesor2
    [tipoEvaluacion]="tipoInstrumento"
    [asignaturaId]="asignaturaId"
    (onReadyAsignatura)="onReady($event)"
></generar-ensayo-profesor2>
