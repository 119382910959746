import { NgModule, ModuleWithProviders } from "@angular/core"
import { FormsModule } from "@angular/forms"
import { CommonModule } from "@angular/common"
import { UsuariosModule } from "@puntaje/puntaje/new-modules/usuarios"
import { MaterialesModule } from "@puntaje/puntaje/new-modules/materiales"
import { Angular2FontawesomeModule } from "@ogr-sa/angular2-fontawesome"

//import { LoginComponent } from './login/login.component';

import { routing } from "./profesores_usuarios.routing"
import { UtilModule, FormModule, ModelViewModule } from "@puntaje/shared/core"
import { ProfesoresLayoutsModule } from "../layouts/profesores_layouts.module"
import { SharedLayoutsModule } from "@puntaje/platforms/zero"

//import { UsuariosComponent }  from './usuarios.component';
//import { UsuariosNewComponent }  from './usuarios.new.component';

//import { UsuariosDeleteComponent }  from './usuarios.delete.component';
//import { UsuariosToolNavbarComponent } from './usuarios.toolnavbar.component';
//import { UsuarioProfileComponent } from './usuario.profile.component';
//import {  } from './register/register.component';
//import { EdicionComponent } from './edicion/edicion.component';

import { PuntajeUsuariosModule } from "@puntaje/puntaje/core"
import { CUILayoutsModule } from "@puntaje/shared/cui"

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ProfesoresLayoutsModule,
        UtilModule,
        UsuariosModule,
        ModelViewModule,
        routing,
        SharedLayoutsModule,
        Angular2FontawesomeModule,
        MaterialesModule,
        PuntajeUsuariosModule,
        CUILayoutsModule
    ],
    declarations: [],
    exports: []
})
export class ProfesoresUsuariosModule {}
