import { Routes, RouterModule } from "@angular/router"
import { AuthGuard } from "@puntaje/shared/core"
import { LoggedLayoutComponent } from "../layouts/logged_layout/logged_layout.component"
import { AyudaComponent, AyudasComponent } from "@puntaje/puntaje/core"

export const profesoresAyudaRoutes: Routes = [
    { 
        path: "ayudas", 
        component: LoggedLayoutComponent,
        children: [
            {
                path: "",
                component: AyudasComponent,
                canActivate: [AuthGuard]
            }
        ]
    },
    {
        path: "ayudas/:id",
        component: LoggedLayoutComponent,
        children: [
            {
                path: "",
                component: AyudaComponent,
                canActivate: [AuthGuard]
            }
        ]
    }
]

export const profesoresAyudaRouting = RouterModule.forChild(profesoresAyudaRoutes)
