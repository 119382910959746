import { NgModule } from "@angular/core"
import { CommonModule } from "@angular/common"
import { Angular2FontawesomeModule } from "@ogr-sa/angular2-fontawesome"

import { routing } from "./profesores_dudas.routing"
import { DudasModule } from "@puntaje/puntaje/new-modules/dudas"
import { ApiModule as PuntajeApiModule } from "@puntaje/puntaje/api-services"
import { ProfesoresLayoutsModule } from "../layouts/profesores_layouts.module"
import { PuntajeDudasModule } from "@puntaje/puntaje/core"

@NgModule({
    imports: [
        CommonModule, 
        PuntajeApiModule, 
        ProfesoresLayoutsModule, 
        DudasModule, 
        routing, 
        Angular2FontawesomeModule,
        PuntajeDudasModule
    ],
    declarations: [],
    exports: []
})
export class ProfesoresDudasModule {}
