import { NgModule } from "@angular/core"
import { FormsModule, ReactiveFormsModule } from "@angular/forms"
import { CommonModule } from "@angular/common"
import { RouterModule } from "@angular/router"

import { routing } from "./profesores_ensayos.routing"

import { EnsayosModule } from "@puntaje/puntaje/new-modules/ensayos"
import { SharedModule } from "@puntaje/puntaje/new-modules/shared"
import { InstrumentosModule } from "@puntaje/puntaje/new-modules/instrumentos"

import { ProfesoresLayoutsModule } from "../layouts/profesores_layouts.module"
import { Angular2FontawesomeModule } from "@ogr-sa/angular2-fontawesome"

import { HomeSaberComponent } from "./home_saber.component"
import { HomeSaber11Component } from "./home_saber11.component"
import { HomePruebasComponent } from "./home_pruebas.component"

import { ResultadosEvaluacionComponent } from "./resultados-evaluacion/resultados-evaluacion.component"

import { UtilModule, FormModule, TranslationsModule } from "@puntaje/shared/core"
import { NebuModule } from "@puntaje/nebulosa/api-services"
import { PuntajeEnsayosModule, PuntajeEstadisticasModule } from "@puntaje/puntaje/core"
import { CUILayoutsModule } from "@puntaje/shared/cui"

@NgModule({
    imports: [
        RouterModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        EnsayosModule,
        InstrumentosModule,
        SharedModule,
        ProfesoresLayoutsModule,
        UtilModule,
        routing,
        Angular2FontawesomeModule,
        FormModule,
        NebuModule,
        TranslationsModule,
        CUILayoutsModule,
        PuntajeEnsayosModule,
        PuntajeEstadisticasModule
    ],
    declarations: [HomeSaberComponent, HomeSaber11Component, HomePruebasComponent, ResultadosEvaluacionComponent],
    exports: []
})
export class ProfesoresEnsayosModule {}
