import { Component, OnInit, ViewChild, Output, Input, EventEmitter, SimpleChanges, ElementRef } from "@angular/core"
import { Asignatura, Asignaturas } from "@puntaje/nebulosa/api-services"
import { Subscription, Observable, BehaviorSubject, combineLatest } from "rxjs"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig
import { filter } from "rxjs/operators"
import { Store, select } from "@ngrx/store"
import { State, AsignaturasByEvaluacionTipo, selectAsignaturasByEvaluacionTipo } from "@puntaje/puntaje/store"

@Component({
    selector: "menu-asignaturas-dropdown",
    templateUrl: "menu_asignaturas_dropdown.component.html",
    styleUrls: ["menu_asignaturas_dropdown.component.scss"],
    host: {
        "(document:click)": "onClickOutside($event)"
    }
})
export class MenuAsignaturasDropdownComponent implements OnInit {
    @Input() asignaturaId: number = 0
    @Output() onReady: EventEmitter<any> = new EventEmitter<any>()
    @Input() tipoEvaluacion: string
    @Input() tipoAsignaturas: string
    lista_asignaturas: any = []
    indexCurrentAsignatura: number = 0
    showOptions: boolean = false

    tipoEvaluacionSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null)
    asignaturasByEvaluacionTipo$: Observable<AsignaturasByEvaluacionTipo> = this.store.pipe(
        select(selectAsignaturasByEvaluacionTipo),
        filter(x => !!x)
    )

    constructor(protected store: Store<State>, private el: ElementRef) {}

    ngOnInit() {
        // sacar esto cuando los componentes dejen de tener un campo tipoAsignaturas
        if (!this.tipoEvaluacion) {
            this.tipoEvaluacion = this.tipoAsignaturas
            this.tipoEvaluacionSubject.next(this.tipoEvaluacion)
        }

        combineLatest(this.tipoEvaluacionSubject.pipe(filter(x => !!x)), this.asignaturasByEvaluacionTipo$).subscribe(
            ([tipoEvaluacion, asignaturasByEvaluacionTipo]) => {
                this.lista_asignaturas =
                    asignaturasByEvaluacionTipo[tipoEvaluacion || config.plataforma.evaluacionDefault]
                this.setCurrentAsignatura()
            }
        )
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes["asignaturaId"]) {
            this.setCurrentAsignatura()
        }
        if (changes["tipoEvaluacion"]) {
            this.tipoEvaluacionSubject.next(this.tipoEvaluacion)
        }
    }

    setCurrentAsignatura() {
        if (this.lista_asignaturas) {
            this.lista_asignaturas.forEach((a, index) => {
                if (this.asignaturaId == a.id) {
                    this.indexCurrentAsignatura = index
                }
            })
        }
    }

    onClickOutside(event) {
        if (!this.el.nativeElement.contains(event.target) && this.showOptions) {
            this.showOptions = false
        }
    }

    toggleShowOptions() {
        this.showOptions = !this.showOptions
    }

    onSelectAsignatura(asignatura_id: number) {
        this.asignaturaId = asignatura_id
        this.onReady.emit(this.asignaturaId)
    }
}
