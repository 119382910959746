import { Routes, RouterModule } from "@angular/router"
import { AuthGuard } from "@puntaje/shared/core"
import { TokenGuard } from "@puntaje/puntaje/services"

import { LoggedLayoutComponent } from "../layouts/logged_layout/logged_layout.component"
import {
    EstadisticasComponent,
    ProgresoComponent,
    ResumenGeneralComponent,
    EstadisticasMultiplesComponent,
    ResumenUsoComponent
} from "@puntaje/puntaje/core"

export const estadisticasRoutes: Routes = [
    {
        path: "estadisticas/resumen_uso",
        component: LoggedLayoutComponent,
        children: [
            {
                path: "",
                component: ResumenUsoComponent,
                canActivate: [AuthGuard],
                data: { enableInformeUso: false, enableInformeAnual: false, enableAvancePlanesAdaptive: true }
            }
        ]
    },
    {
        path: "estadisticas",
        component: LoggedLayoutComponent,
        children: [
            { path: "", component: EstadisticasComponent, canActivate: [AuthGuard], data: { routeVolver: "/pruebas" } }
        ]
    },
    {
        path: "resumen_general",
        component: LoggedLayoutComponent,
        children: [
            {
                path: "",
                component: ResumenGeneralComponent,
                canActivate: [TokenGuard, AuthGuard]
            }
        ]
    },
    {
        path: "progreso",
        component: LoggedLayoutComponent,
        children: [
            {
                path: "",
                component: ProgresoComponent,
                canActivate: [TokenGuard, AuthGuard]
            }
        ]
    }
]

export const estadisticasRouting = RouterModule.forChild(estadisticasRoutes)
