import { Routes, RouterModule } from "@angular/router"
import { AuthGuard } from "@puntaje/shared/core"
import { LoggedLayoutComponent } from "../layouts/logged_layout/logged_layout.component"
import { NotificacionesComponent } from "@puntaje/puntaje/core"

export const routes: Routes = [
    { 
        path: "notificaciones", 
        component: LoggedLayoutComponent,
        children: [
            {
                path: "",
                component: NotificacionesComponent, canActivate: [AuthGuard]
            }
        ]
    }
]

export const routing = RouterModule.forChild(routes)
