import { Routes, RouterModule } from "@angular/router"
import { AuthGuard } from "@puntaje/shared/core"
import { TokenGuard } from "@puntaje/puntaje/services"

import { LoggedLayoutComponent } from "../layouts/logged_layout/logged_layout.component"
import { NoticiaComponent, NoticiasProfesorComponent } from "@puntaje/puntaje/core"

export const routes: Routes = [
    {
        path: "noticias/:id",
        component: LoggedLayoutComponent,
        children: [
            {
                path: "",
                component: NoticiaComponent,
                canActivate: [TokenGuard, AuthGuard]
            }
        ]
    },
    {
        path: "noticias",
        component: LoggedLayoutComponent,
        children: [
            {
                path: "",
                component: NoticiasProfesorComponent,
                canActivate: [AuthGuard]
            }
        ]
    }
]

export const routing = RouterModule.forChild(routes)
