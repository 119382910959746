import { NgModule, ModuleWithProviders } from "@angular/core"
import { CommonModule } from "@angular/common"
import { FormsModule } from "@angular/forms"
import { Angular2FontawesomeModule } from "@ogr-sa/angular2-fontawesome"
import { UtilModule } from "@puntaje/shared/core"

import { ProfesoresLayoutsModule } from "../layouts/index"
import { CommonsLayoutsModule } from "@puntaje/shared/layouts"
import { EstablecimientosModule } from "@puntaje/puntaje/new-modules/establecimientos"

import { establecimientosRouting } from "./profesores_establecimientos.routing"
import { HomeEstablecimientosComponent } from "./home_establecimientos.component"
import { PuntajeEstablecimientosModule } from "@puntaje/puntaje/core"
import { CUILayoutsModule } from "@puntaje/shared/cui"

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        establecimientosRouting,
        ProfesoresLayoutsModule,
        CommonsLayoutsModule,
        EstablecimientosModule,
        Angular2FontawesomeModule,
        UtilModule,
        PuntajeEstablecimientosModule,
        CUILayoutsModule
    ],
    declarations: [HomeEstablecimientosComponent],
    exports: []
})
export class ProfesoresEstablecimientosModule {
    static forRoot(): ModuleWithProviders<ProfesoresEstablecimientosModule> {
        return {
            ngModule: ProfesoresEstablecimientosModule,
            providers: []
        }
    }
}
