<div class="component-container print-hide" *ngIf="lista_asignaturas.length > 0" (click)="toggleShowOptions()">
    <div class="dropdown-btn print-hide">
        <ogr-icon
            name="{{ lista_asignaturas[indexCurrentAsignatura].icono }}"
            class="ogricon {{ lista_asignaturas[indexCurrentAsignatura].clase }}"
            align="middle"
        ></ogr-icon>
        {{ lista_asignaturas[indexCurrentAsignatura].asignaturaPlataforma }}
        <div class="color-circle {{ lista_asignaturas[indexCurrentAsignatura].clase }}">
            <span class="glyphicon glyphicon-triangle-bottom" aria-hidden="true"></span>
        </div>
    </div>
    <ul class="lista" [class.show-lista]="showOptions">
        <ng-template ngFor let-a [ngForOf]="lista_asignaturas">
            <li *ngIf="asignaturaId != a.id" (click)="onSelectAsignatura(a.id)">
                <span>
                    <ogr-icon name="{{ a.icono }}" class="ogricon {{ a.clase }}" align="middle"></ogr-icon>
                    {{ a.asignaturaPlataforma }}
                </span>
            </li>
        </ng-template>
    </ul>
</div>
