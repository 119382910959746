import { Component } from "@angular/core"
import { MenuItemBoxData } from "@puntaje/shared/layouts"
import { MenuComponent } from "@puntaje/puntaje/new-modules/shared"
import { Asignaturas } from "@puntaje/nebulosa/api-services"
import { Store } from "@ngrx/store"
import { State } from "@puntaje/puntaje/store"
import { last, first } from "rxjs/operators"

@Component({
    templateUrl: "home_saber.component.html"
})
export class HomeSaberComponent extends MenuComponent {
    menuItems: MenuItemBoxData[] = []

    constructor(protected store: Store<State>) {
        super(store)
    }

    ngOnInit() {
        super.ngOnInit()
        this.setMenuItems()
    }

    async setMenuItems() {
        let asignaturasByEvaluacionTipo = await this.asignaturasByEvaluacionTipo$.pipe(first()).toPromise()

        this.menuItems = [
            {
                route: "/saber/generar_simulacro/" + asignaturasByEvaluacionTipo["saber"][0].id,
                params: { tipo_instrumento: "saber" },
                label: "Generar Simulacro Saber",
                text: "Genera simulacros y compártelos con tus estudiantes. Con opciones personalizables.",
                linkText: "Comenzar",
                icon: "hacer-prueba"
            },
            {
                route: "/saber/compartidas",
                params: { tipo_instrumento: "saber", asignatura_id: asignaturasByEvaluacionTipo["saber"][0].id },
                label: "Historial de simulacros compartidos, no compartidos y presenciales",
                text: "Encuentra aquí la lista de todos los simulacros compartidos, no compartidos y presenciales.",
                linkText: "Ver historial",
                icon: "guia"
            },
            {
                route: "/saber/progreso/",
                params: { tipo_instrumento: "saber" },
                label: "Progreso de tus estudiantes",
                text: "Observa la evolución en el tiempo de los resultados obtenidos por los estudiantes.",
                linkText: "Ver progreso",
                icon: "estadistica-o"
            },
            {
                route: "/saber/estadisticas/",
                params: { tipo_instrumento: "saber", asignatura_id: asignaturasByEvaluacionTipo["saber"][0].id },
                label: "Estadísticas Simulacros",
                text: "Encuentra aquí estadísticas generadas a partir de los resultados obtenidos en los simulacros.",
                linkText: "Ver estadísticas",
                icon: "estadistica-o"
            },
            {
                route: "/evaluacion_instancias/subir",
                label: "Cargar Resultados",
                text: "Sube resultados de simulacros a partir de una hoja de respuesta o una planilla CSV.",
                linkText: "Subir resultados",
                icon: "hacer-prueba"
            }
        ]
    }
}
