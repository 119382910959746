import { Component } from "@angular/core"
import { MenuItemBoxData } from "@puntaje/shared/layouts"
import { MenuComponent } from "@puntaje/puntaje/new-modules/shared"
import { Asignaturas } from "@puntaje/nebulosa/api-services"
import { Store } from "@ngrx/store"
import { State } from "@puntaje/puntaje/store"
import { last, first } from "rxjs/operators"

@Component({
    templateUrl: "home_pruebas.component.html"
})
export class HomePruebasComponent extends MenuComponent {
    menuItems: MenuItemBoxData[] = []

    constructor(protected store: Store<State>) {
        super(store)
    }

    ngOnInit() {
        super.ngOnInit()
        this.setMenuItems()
    }

    async setMenuItems() {
        let asignaturasByEvaluacionTipo = await this.asignaturasByEvaluacionTipo$.pipe(first()).toPromise()

        this.menuItems = [
            {
                route: "/pruebas/generar_prueba/" + asignaturasByEvaluacionTipo["curricular"][0].id,
                params: { tipo_instrumento: "curricular" },
                label: "Generar prueba curricular",
                text: "Genera pruebas de contenido curricular y compártelas con tus estudiantes. Con opciones personalizables.",
                linkText: "Comenzar",
                icon: "hacer-prueba"
            },
            {
                route: "/pruebas/compartidas/",
                params: { tipo_instrumento: "curricular", asignatura_id: 1 },
                label: "Historial de pruebas compartidas, no compartidas y presenciales",
                text: "Encuentra aquí la lista de todas las pruebas compartidas, no compartidas y presenciales.",
                linkText: "Ver historial",
                icon: "guia"
            },
            {
                route: "/pruebas/progreso/",
                params: { tipo_instrumento: "curricular" },
                label: "Progreso de tus estudiantes",
                text: "Observa la evolución en el tiempo de los resultados obtenidos por tus estudiantes.",
                linkText: "Ver progreso",
                icon: "estadistica-o"
            },
            {
                route: "/pruebas/estadisticas/",
                params: { tipo_instrumento: "curricular", asignatura_id: 1 },
                label: "Estadísticas de pruebas curriculares",
                text: "Encuentra aquí estadísticas generadas a partir de los resultados.",
                linkText: "Ver estadísticas",
                icon: "estadistica-o"
            }
        ]
    }
}
