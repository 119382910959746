import {
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild
} from "@angular/core"
import { ActivatedRoute, Router } from "@angular/router"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig
import {
    Asignatura,
    Asignaturas,
    Clasificaciones,
    GeneradorInstrumento,
    GeneradorInstrumentos,
    Preguntas
} from "@puntaje/nebulosa/api-services"
import { Evaluaciones, GrupoUsuarios, Instrumento, Instrumentos } from "@puntaje/puntaje/api-services"
import { AbstractGenerarEnsayoComponent } from "./abstract_generar_ensayo.component"

declare let $

@Component({
    selector: "generar-ensayo-profesor2",
    templateUrl: "generar_ensayo_profesor2.component.html",
    styleUrls: ["generar_ensayo_profesor.component.scss"]
})
export class GenerarEnsayoProfesor2Component extends AbstractGenerarEnsayoComponent implements OnInit, OnChanges {
    @ViewChild("loadingLayoutSearch") loadingLayoutSearch: LoadingLayoutComponent
    @Input() asignaturaId: number
    @Input() enableReporte: boolean = true
    asignatura: Asignatura
    @Output() onReadyAsignatura: EventEmitter<any> = new EventEmitter<any>()

    instrumento: Instrumento

    instrumentoId: number
    buscarInstrumento: boolean = false
    buscado: boolean = false
    instrumentoBuscado: Instrumento
    generadorInstrumentoSelected: GeneradorInstrumento

    nombreEvaluacion: string = ""
    fechaInicio: string = ""
    fechaTermino: string = ""
    fechaMostrarRespuestas: string = ""

    @Input() tipoEvaluacion: string
    zonas: string[]
    zonasSize: { [key: string]: number } = {}
    showZonas = true

    constructor(
        protected route: ActivatedRoute,
        protected asignaturasService: Asignaturas,
        protected router: Router,
        protected generadorInstrumentosService: GeneradorInstrumentos,
        protected preguntasService: Preguntas,
        protected evaluacionesService: Evaluaciones,
        protected grupoUsuariosService: GrupoUsuarios,
        protected clasificacionesService: Clasificaciones,
        protected instrumentosService: Instrumentos,
        protected cdr: ChangeDetectorRef
    ) {
        super(router, generadorInstrumentosService, evaluacionesService)
    }

    ngOnInit() {
        //this.loadData();
        super.ngOnInit()
        this.zonas = config.instrumentosPredefinidos.zonas
        this.zonas.forEach(z => {
            this.zonasSize[z] = 1
        }) //inicializando checkeo
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes["asignaturaId"] || changes["tipoEvaluacion"]) {
            this.showZonas = true
            this.loadData()
        }
        if (changes["tipoEvaluacion"]) {
            this.showZonas = true
        }
    }

    getGenerador(generadorInstrumento: GeneradorInstrumento) {
        this.generadorInstrumentoSelected = generadorInstrumento
    }

    loadData() {
        this.loadingLayout.standby()
        if (this.asignaturaId) {
            this.asignaturasService
                .find(this.asignaturaId, { tipo_instrumento: this.tipoEvaluacion })
                .then((asignatura: Asignatura) => {
                    this.asignatura = asignatura
                    this.onReadyAsignatura.emit(this.asignatura)
                    this.generadorInstrumentos = asignatura.generador_instrumentos
                    this.loadingLayout.ready()
                })
        }
    }

    generarInstrumento() {
        this.loadingLayout.standby()
        let instrumento = new Instrumento() as any
        instrumento.tiempo = this.tiempo
        instrumento.numero_preguntas = this.numeroPreguntas
        instrumento.asignatura_id = this.asignaturaId
        instrumento.generador_instrumento_id = this.generadorInstrumento.id
        instrumento.clasificaciones_id = this.clasificacionesIds
        this.instrumentosService.save(instrumento).then((i: Instrumento) => {
            this.instrumentoBuscado = i
            this.cargarInstrumento()
        })
    }

    cargarInstrumento() {
        this.loadingLayout.standby()
        this.instrumentosService
            .find(this.instrumentoBuscado.id, { with_generador_instrumento: 1 })
            .then((i: Instrumento) => {
                this.instrumento = i
            })
    }

    onReadyInstrumento() {
        this.loadingLayout.ready()
        this.scrollToInstrumento()
    }

    onExternalLoadingInstrumento() {
        this.loadingLayout.standby()
    }

    checkSize(size: any) {
        this.zonasSize[size[1]] = size[0]
        let total = Object.entries(this.zonasSize).reduce(function (total, pair) {
            let [key, value] = pair
            return total + value
        }, 0)
        this.showZonas = total != 0
    }

    buscar(instrumentoId: number) {
        if (!instrumentoId) {
            return
        }
        this.loadingLayoutSearch.standby()
        this.instrumentosService.enableIgnoreCatch()
        this.instrumentosService
            .find(instrumentoId)
            .then((instrumento: Instrumento) => {
                this.loadingLayoutSearch.ready()
                this.buscado = true
                this.instrumentoBuscado = instrumento
            })
            .catch(response => {
                this.loadingLayoutSearch.ready()
                this.buscado = true
                this.instrumentoBuscado = null
            })
    }

    scrollToInstrumento() {
        let target = document.getElementById("instrumentoSeleccionado")
        $("html, body").animate(
            {
                scrollTop: target.offsetTop + 120
            },
            1000,
            "easeIn"
        )
    }

    callbackRecomendado(i: Instrumento) {
        this.instrumento = i
    }
}
