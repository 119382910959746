import { Routes, RouterModule } from "@angular/router"
import { AuthGuard } from "@puntaje/shared/core"
import { TokenGuard } from "@puntaje/puntaje/services"
import { HomeBibliotecaComponent } from "./home_biblioteca.component"
import { LoggedLayoutComponent } from "../layouts/logged_layout/logged_layout.component"
import {
    MaterialAppsComponent,
    MaterialComponent,
    MaterialesComponent,
    MaterialUDDComponent,
    RecursosInteractivosComponent
} from "@puntaje/puntaje/core"

export const materialesRoutes: Routes = [
    {
        path: "bibliotecas",
        component: LoggedLayoutComponent,
        children: [
            { 
                path: "", 
                component: HomeBibliotecaComponent, 
                canActivate: [AuthGuard] 
            }
        ]
    },
    {
        path: "materiales",
        component: LoggedLayoutComponent,
        children: [
            {
                path: "",
                component: MaterialesComponent,
                canActivate: [TokenGuard, AuthGuard]
            }
        ]
    },
    { 
        path: "bibliotecas/materiales", 
        component: LoggedLayoutComponent,
        children: [
            {
                path: "",
                component: MaterialesComponent, 
                canActivate: [TokenGuard, AuthGuard] 
            }
        ]
    },
    {
        path: "materiales/:id",
        component: LoggedLayoutComponent,
        children: [
            {
                path: "",
                component: MaterialComponent,
                canActivate: [TokenGuard, AuthGuard]
            }
        ]
    },
    {
        path: "recursos_interactivos",
        component: LoggedLayoutComponent,
        children: [
            {
                path: "",
                component: RecursosInteractivosComponent,
                canActivate: [TokenGuard, AuthGuard]
            }
        ]
    },
    {
        path: "bibliotecas/recursos_interactivos",
        component: LoggedLayoutComponent,
        children: [
            {
                path: "",
                component: RecursosInteractivosComponent,
                canActivate: [TokenGuard, AuthGuard]
            }
        ]
    },
    {
        path: "material_udd",
        component: LoggedLayoutComponent,
        children: [
            {
                path: "",
                component: MaterialUDDComponent,
                canActivate: [TokenGuard, AuthGuard]
            }
        ]
    },
    {
        path: "material_app",
        component: LoggedLayoutComponent,
        children: [
            {
                path: "",
                component: MaterialAppsComponent,
                canActivate: [TokenGuard, AuthGuard]
            }
        ]
    },
    { 
        path: "biblioteca", 
        component: LoggedLayoutComponent,
        children: [
            {
                path: "",
                component: MaterialesComponent,
                canActivate: [TokenGuard, AuthGuard]
            }
        ]
    },
    { 
        path: "bibliotecas/biblioteca", 
        component: LoggedLayoutComponent,
        children: [
            {
                path: "",
                component: MaterialesComponent,
                canActivate: [TokenGuard, AuthGuard]
            }
        ]
    }
]

export const materialesRouting = RouterModule.forChild(materialesRoutes)
