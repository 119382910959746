<cui-evaluation-layout [contenidoLateralXL]="false">
    <cui-layout-header>
        <base-navbar></base-navbar>
    </cui-layout-header>
    <cui-layout-footer>
        <base-footer></base-footer>
        <div class="edificios-wrapper"><div class="edificios"></div></div>
        <simple-modal></simple-modal>
    </cui-layout-footer>
</cui-evaluation-layout>
