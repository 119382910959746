import { Component, OnInit } from "@angular/core"
import { MenuItemBoxData } from "@puntaje/shared/layouts"
import { MenuComponent } from "@puntaje/puntaje/new-modules/shared"
import { Store } from "@ngrx/store"
import { State } from "@puntaje/puntaje/store"
import { AuthService } from "@puntaje/shared/core"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig

@Component({
    selector: "resultados-evaluacion",
    templateUrl: "./resultados-evaluacion.component.html",
    styleUrls: ["./resultados-evaluacion.component.scss"]
})
export class ResultadosEvaluacionComponent extends MenuComponent implements OnInit {
    menuItems: MenuItemBoxData[] = []
    tipoInstrumento: string

    constructor(protected store: Store<State>, protected authService: AuthService) {
        super(store)
    }

    ngOnInit() {
        this.tipoInstrumento = config.plataforma.evaluacionDefault
        super.ngOnInit()
        this.setMenuItems()
    }

    async setMenuItems() {
        const asignaturasByEvaluacionTipo = await this.asignaturasByEvaluacionTipo$.toPromise()

        this.menuItems = [
            {
                route: "/evaluaciones/resultados/historial",
                params: {},
                label: "Historial",
                text: "Encuentra aquí la lista de todas las evaluaciones online, impresas y sin configurar.",
                linkText: "Ver historial",
                icon: "guia"
            },
            {
                route: "/estadisticas",
                params: { tipo_instrumento: this.tipoInstrumento },
                label: "Estadísticas",
                text: "Encuentra aquí estadísticas generadas a partir de los resultados obtenidos en evaluaciones.",
                linkText: "Ver estadísticas",
                icon: "estadistica-o"
            },
            {
                route: "/evaluaciones/resultados/libro",
                params: {},
                label: "Libro de Clases",
                text: "Aquí podrás encontrar información de tus alumnos y las evaluaciones que han rendido.",
                linkText: "Ver libro",
                icon: "libro-o"
            }
        ]
    }
}
